/*
BLOT: This file basically controls the dynamic rendering of each and every blog post.
The basic idea is that we render HTML dynamically using JavaScript and this is what react helps with.
*/

// Curly braces i.e. {} means import the named exports from those modules
// You can import a exported variable, function, class or object from other modules/components.
import React from "react"; // Import React so that we can use it
import PropTypes from "prop-types"; // PropTypes will be used later to validate the props that are passed to the component
import { kebabCase } from "lodash"; // Lodash is a library that will be used to manipulate the tags
import { Helmet } from "react-helmet"; // React Helmet will be used to dynamically set the page title and meta description
import { graphql, Link } from "gatsby"; // Gatsby's graphql function will be used to query for the data we need, and Link will be used to link to other pages
import Layout from "../components/Layout"; // This is the component that will be used to wrap the page
import Content, { HTMLContent } from "../components/Content"; // This is the component that will render the page content
import useSiteMetadata from "../components/SiteMetadata";
import 'giscus';


/* Here is the explanation for the code below:
1. We are declaring a function BlogPostTemplate that takes a set of parameters
2. content, contentComponent, description, tags, title, and helmet are the parameters
3. content and title are the only required parameters
4. contentComponent is a component that will be used to render the content of the blog post
5. helmet is a component that will be used to add meta tags to the page */
// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  date,
  readingTimeValue,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section-blog-posts">
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
          <div class="icon-text-group">
          <div class="icon-text-container">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path></svg>
            <p>{date}</p>
            </div>
            <div class="icon-text-container">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path></svg>
            <p>{readingTimeValue}</p>
            </div>
          </div>                 
            <hr></hr>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr></hr>
            <p>{description}</p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <hr></hr>
                <h3>Explore Tags</h3>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <hr></hr>
            <giscus-widget
              id="comments"
              repo="optimsight/blog-giscus-comments"
              repoid="R_kgDOLKhl3Q"
              category="Announcements"
              categoryid="DIC_kwDOLKhl3c4Ccver"
              mapping="pathname"              
              reactionsenabled="1"
              emitmetadata="0"
              inputposition="top"
              theme="light"
              lang="en"
              loading="lazy"
            ></giscus-widget>         
          </div>
        </div>
      </div>
    </section>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  readingTimeValue: PropTypes.string,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        readingTimeValue={post.fields.readingTime.text}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:description" content={`${post.frontmatter.description}`} />
            <meta property="article:published_time" content={`${post.frontmatter.date}`} />
            <meta property="og:site_name" content={`${siteUrl}`} />
            <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
            <meta property="og:image" content={`${siteUrl}${post.frontmatter.featuredimage.publicURL}`} />
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@predinfer"/>
            <meta name="twitter:creator" content="@predinfer"/>       
            <meta name="twitter:title" content={`${post.frontmatter.title}`} />
            <meta name="twitter:description" content={`${post.frontmatter.description}`} />
            <meta name="twitter:image" content={`${siteUrl}${post.frontmatter.featuredimage.publicURL}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

// Get the Markdown file data
/* Here is the explanation for the code below:
1. This query is used by the page template to get the data it needs.
2. It uses the id variable from createPage() to get the markdown post.
3. The id variable is passed into the query as $id and results in the query being run once per post.
4. The result is stored in the markdownRemark variable, as specified in the query.
5. Then, the id, html, and frontmatter fields are pulled out of the markdownRemark variable and used in the page template.
6. The markdownRemark id is used as the react key, and the frontmatter title is used in the page header.
7. The markdownRemark html is used to render the page body. */
export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          publicURL
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;
